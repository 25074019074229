<template>
  <div class="body-container">
    <div class="purple-gradient pt-3 pt-sm-6 pb-8 pb-sm-16">
      <div class="mb-16 pb-10">
        <v-btn
          depressed
          plain
          dark
          :ripple="false"
          :to="{ name: 'UserMainPage' }"
          class="v-btn--active"
          :class="$vuetify.breakpoint.xs ? 'pt-6' : 'pt-0 p'"
        >
          <v-img eager src="@/assets/icons/LogoZaturnaHorizontal.svg" />
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="d-flex flex-column align-center">
        <div class="text-h1 font-weight-bold secondary--text">Error</div>
        <div>
          <v-img
            :style="
              $vuetify.breakpoint.xs ? { width: '250px' } : { width: '450px' }
            "
            src="@/assets/img/Error_404.png"
          />
        </div>
        <div class="text-center text-h4 text-sm-h3 my-6 my-sm-8 px-8 px-md-0">
          No hemos podido encontrar la página que buscas.
        </div>
        <div>
          <Button
            text="Volver a inicio"
            :aria-label="
              provider
                ? 'Volver a página de inicio de modo proveedor'
                : 'Volver a página de inicio'
            "
            :url="provider ? 'ProviderHomepage' : 'MainPage'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";

export default {
  name: "ErrorPage",
  components: {
    Button,
  },
  computed: mapState({
    provider(state) {
      return state.provider;
    },
    user(state) {
      return state.user;
    },
  }),
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 20%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
</style>
